import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { Layout } from "./components/layout";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import { Dashboard } from "./pages/dashboard/dashboard";
import { FinanceManagementList } from "./pages/finance-management/list";
import { NotFound } from "./pages/not-found";
import { PrivilegeList } from "./pages/privilege/list";
import { PrivilegeSettings } from "./pages/privilege/settings";
import { UserList } from "./pages/user-management/list";
import { UserProfile } from "./pages/user-management/profile";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));
          const auth = await AuthApi.me();
          dispatch(authMe(auth));
        }

        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/user" element={<UserList />} />
        <Route path="/user/:id" element={<UserProfile />} />
        {/* <Route path="/rule" element={<RuleList />} /> */}
        <Route path="/roles" element={<PrivilegeList />} />
        <Route path="/roles/:id" element={<PrivilegeSettings />} />
        <Route path="/roles/:id/:type" element={<PrivilegeSettings />} />
        <Route path="/finance" element={<FinanceManagementList />} />
        <Route path="/access" element={<Access />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
