import { Avatar, Divider, Flex, Text } from "@mantine/core";
import { IconEdit, IconFileCheck, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RoleApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { PopoverText } from "../../components/popover-text/popover-text";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { RowActionIcon } from "../../components/table/row-action-icon";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { IUserControl } from "../../interfaces/IUserControl";
import { dateTimeFormat } from "../../utils/date";

export function PrivilegeList() {
  const breadcrumbs = useBreadcrumb();
  const [filters] = React.useState({ query: "" });
  const { roles = [], departmentUnits, roleTypes, roleStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const ref = React.useRef<ITableRef>(null);
  const navigate = useNavigate();

  const columns = useHeader({
    roles,
    departmentUnits,
    roleTypes,
    roleStatus,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          navigate(`/roles/${item.id}`);
          break;
        }
        case "details": {
          navigate(`/roles/${item.id}/details`);
          break;
        }
      }
    },
  });
  return (
    <>
      <PageLayout title="Эрхийн тохиргоо" subTitle="Системд ашиглах эрх, түүний тохиргооны жагсаалт" breadcrumb={breadcrumbs}>
        <Divider mb="lg" />
        <Table ref={ref} name="user.control.roles.lists" columns={columns} filters={filters} loadData={(data) => RoleApi.list(data!)} />
      </PageLayout>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IUserControl) => void;
  roles: IReference[];
  departmentUnits: any;
  roleTypes: IReference[];
  roleStatus: IReference[];
};

const useHeader = ({ onClick, roleTypes, roleStatus }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    align: "left",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      if (record.type === "STANDARD") {
        return (
          <RowActionIcon
            onClick={(key) => onClick(key, record)}
            extra={[
              {
                text: "Дэлгэрэнгүй",
                icon: <IconFileCheck />,
                key: "details",
              },
            ]}
          />
        );
      } else
        return (
          <RowActionIcon
            onClick={(key) => onClick(key, record)}
            extra={[
              {
                text: "Засах",
                icon: <IconEdit />,
                key: "edit",
              },
              {
                text: "Дэлгэрэнгүй",
                icon: <IconFileCheck />,
                key: "details",
              },
            ]}
          />
        );
    },
  },
  {
    title: "Ролийн нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.name}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => (
      <Tag
        color={roleTypes?.find((f: IReference) => f.code === record.type)?.color ?? "blue"}
        name={roleTypes?.find((f: IReference) => f.code === record.type)?.name ?? "-"}
      />
    ),
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record?.description}</PopoverText>,
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) =>
      record?.regUser ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.regUser?.avatar}>
            <IconPhoto />
          </Avatar>
          <Text size="sm">
            {record?.regUser?.lastName ?? ""} {record?.regUser?.firstName ?? "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => (
      <Text size="sm" w="max-content">
        {dateTimeFormat(record?.createdAt)}
      </Text>
    ),
  },
  {
    title: "Хэрэглэгч тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.userCount}
      </Text>
    ),
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "roleStatus",
    render: (record) => {
      return (
        <Tag
          color={roleStatus.find((item: IReference) => item?.code === record?.roleStatus)?.color ?? "gray.5"}
          name={roleStatus.find((item: IReference) => item?.code === record?.roleStatus)?.name ?? "-"}
        />
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Эрхийн тохиргоо",
  },
];
