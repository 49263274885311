import { Avatar, Flex, Paper, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import React, { Ref } from "react";
import { useSelector } from "react-redux";
import { FinanceSettingsApi } from "../../apis";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IApprovelRuleLevel } from "../../interfaces/IApprovelRuleLevel";
import { IGeneral } from "../../interfaces/IGeneral";
import { Tag } from "../tags";

export function RoleHistoryList({ action, setAction, tableRef }: { action: any; setAction: any; tableRef: Ref<ITableRef> | undefined }) {
  const [filters] = React.useState({ query: "" });
  const { bankNames, financeRoleStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    bankNames,
    financeRoleStatus,
    onClick: (key, item) => {
      switch (key) {
        case "bank-role": {
          break;
        }
      }
    },
  });

  return (
    <Table
      placeholder={
        <Text fw={500} ta="center">
          Банктай харилцах ажилтны эрх тохируулаагүй байна
        </Text>
      }
      ref={tableRef}
      name="/usr/finance_settings/finance_role"
      columns={columns}
      filters={filters}
      loadData={(data) => FinanceSettingsApi.role_list(data!)}
    />
  );
}

type HeaderProps = {
  onClick: (key: string, record: IApprovelRuleLevel) => void;
  bankNames: any[];
  financeRoleStatus: any[];
};

const useHeader = ({ onClick, bankNames, financeRoleStatus }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Банкны нэр",
    sorter: true,
    render: (record) => (
      <Flex align="center" gap={10}>
        <Avatar src={bankNames?.find((item: any) => item.code === record?.bank)?.icon || "-"}>
          <IconPhoto />
        </Avatar>
        <Text size="sm">{bankNames?.find((item: any) => item.code === record?.bank)?.name || "-"}</Text>
      </Flex>
    ),
  },
  {
    title: "Бизнесийн #",
    sorter: true,
    render: (record) => {
      return record?.business ? (
        <Text size="sm" w="max-content">
          #{record?.business?.refCode || "-"}
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    render: (record) => {
      return record?.business ? (
        <Text size="sm" w="max-content">
          {record?.business?.profileName || "-"}
        </Text>
      ) : (
        "-"
      );
    },
  },
  {
    title: "SCF/DCF санхүү",
    sorter: true,
    render: (record) => {
      return record?.user ? (
        <Flex gap={10} align="center">
          <Paper withBorder radius="xl">
            <Avatar src={record?.user?.avatar} />
          </Paper>
          <Text tt="capitalize" size="sm" w="max-content">
            {record.user?.lastName ? record.user?.lastName[0] + "." : ""} {record?.user?.firstName}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Хэрэглэгчийн нэр",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.user?.username ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "И-мэйл",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.user?.email ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Гар утас",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.user?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эрх статус",
    sorter: true,
    render: (record) => {
      return (
        <Tag
          color={financeRoleStatus.find((item: any) => item.code === record.financeRoleStatus)?.color || "gray.5"}
          name={financeRoleStatus.find((item: any) => item.code === record.financeRoleStatus)?.name || "-"}
        />
      );
    },
  },
];
