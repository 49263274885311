import { Table as BaseTable, Button, Center, Collapse, LoadingOverlay, Pagination, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconChevronUp, IconDatabaseOff, IconMinus, IconPlus, IconSelector } from "@tabler/icons-react";
import qs from "qs";
import React, { ReactElement, useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./row-action";

export type ColumnType<T> = {
  title: string | ReactElement;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  columns: ColumnType<any>[];
  filters?: { [key: string]: string | number };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  collapse?: boolean;
  collapseChildren?: any;
  placeholder?: any;
};

export type ITableRef = { reload: () => void };

export const Table = React.forwardRef(
  (
    { name, columns, placeholder, filters = {}, pagination = true, dataSource = [], loadData, collapse = false, collapseChildren }: Props,
    ref: React.Ref<ITableRef>,
  ) => {
    const { classes } = useStyles();
    const [opened, setOpened] = React.useState<number[]>([]);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const [offset, setOffset] = React.useState({ page: 1, limit: 20 });

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );
    const [sorted, setSorting] = React.useState<string[]>([]);

    const onSort = (index?: string) => {
      if (index) setSorting((state) => [index, state[0] === index ? (state[1] === "asc" ? "desc" : "asc") : "asc"]);
    };

    const onScroll = () => {};

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    return (
      <div className={classes.container}>
        <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
          <BaseTable ref={tableRef} horizontalSpacing="md" verticalSpacing="sm" highlightOnHover striped="even">
            <BaseTable.Thead>
              <BaseTable.Tr>
                {collapse && <BaseTable.Th className={classes.collapseTh}></BaseTable.Th>}
                {columns.map((column, index) => (
                  <Th key={`table-head-${index}`} column={column} sorted={sorted[0] === column.dataIndex && sorted[1]} onSort={() => onSort(column.dataIndex)}>
                    {column.title}
                  </Th>
                ))}
              </BaseTable.Tr>
            </BaseTable.Thead>
            <BaseTable.Tbody>
              {data?.rows?.length > 0 ? (
                data?.rows?.map((row: any, index: number) => (
                  <React.Fragment key={row.uniqueId ?? index}>
                    <BaseTable.Tr>
                      {collapse && (
                        <BaseTable.Td>
                          <Button
                            onClick={() => setOpened(opened.includes(index) ? opened.filter((f) => f !== index) : [...opened, index])}
                            size="xs"
                            w={25}
                            h={25}
                            p={0}
                            variant="default">
                            {opened.includes(index) ? <IconPlus size={18} stroke={1} /> : <IconMinus size={18} stroke={1} />}
                          </Button>
                        </BaseTable.Td>
                      )}
                      {columns.map((column, index2) => (
                        <BaseTable.Td key={`${row.uniqueId || index}-${index2}`} align={column.align ?? "left"}>
                          <div style={{ width: "max-content" }}>{column.render(row, index + (offset.page - 1) * offset.limit)}</div>
                        </BaseTable.Td>
                      ))}
                    </BaseTable.Tr>
                    {collapse && (
                      <BaseTable.Tr>
                        <BaseTable.Td colSpan={columns.length + 1}>
                          <Collapse in={opened.includes(index)}>
                            <div className={classes.collapseContent}>{collapseChildren(row)}</div>
                          </Collapse>
                        </BaseTable.Td>
                      </BaseTable.Tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <BaseTable.Tr key="nothing">
                  <BaseTable.Td colSpan={columns.length + (collapse ? 1 : 0)}>
                    {placeholder || (
                      <Center sx={(theme) => ({ padding: theme.spacing.xl, flexDirection: "column", gap: 10 })}>
                        <IconDatabaseOff stroke={1} size={36} />
                        <Text fw={500} ta="center">
                          Юу ч олдсонгүй
                        </Text>
                      </Center>
                    )}
                  </BaseTable.Td>
                </BaseTable.Tr>
              )}
            </BaseTable.Tbody>
          </BaseTable>

          <LoadingOverlay visible={isLoading} />
        </div>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
            />
          </div>
        )}
      </div>
    );
  },
);

interface ThProps {
  children: React.ReactNode;
  column: ColumnType<any>;
  sorted?: string | unknown;
  onSort(): void;
}

const Th = ({ children, column, sorted, onSort }: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (sorted === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;

  if (!column.sorter)
    return (
      <BaseTable.Th style={{ width: column.width }}>
        <span style={{ whiteSpace: "nowrap" }}>{children}</span>
      </BaseTable.Th>
    );

  return (
    <BaseTable.Th className={`${classes.control}`} style={{ width: column.width }} onClick={() => onSort()}>
      <div className={classes.button}>
        <span className={classes.child}>{children}</span>
        <span className={classes.icon}>
          <Icon size={14} stroke={1.5} />
        </span>
      </div>
    </BaseTable.Th>
  );
};

const useStyles = createStyles((theme) => ({
  collapseTh: {
    width: "24px",
  },
  collapseContent: {
    borderTop: "1px solid #dadada",
    padding: "14px 24px",
  },
  container: {
    position: "relative",
  },
  scroll: {
    overflowX: "auto",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  icon: {
    display: "flex",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
