import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Paper,
  Space,
  Switch,
  Table,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import {
  IconBox,
  IconBuildingBank,
  IconBuildingWarehouse,
  IconCashBanknote,
  IconChartGridDots,
  IconChartPie,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconCoins,
  IconDiscount2,
  IconFileInvoice,
  IconHome2,
  IconId,
  IconInbox,
  IconLayoutList,
  IconListDetails,
  IconMail,
  IconMailForward,
  IconMapSearch,
  IconMoneybag,
  IconNetwork,
  IconPackage,
  IconReceipt,
  IconReceiptOff,
  IconReceiptRefund,
  IconReceiptTax,
  IconRepeat,
  IconReport,
  IconSettings,
  IconTruck,
  IconTruckDelivery,
  IconUser,
  IconUserCheck,
  IconUserDollar,
  IconUserSearch,
  IconUsers,
  IconWallet,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSWR from "swr";
import * as yup from "yup";
import { RoleApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

const schema = yup.object({
  name: yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable().trim(),
  description: yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable().trim(),
  modules: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

export function PrivilegeSettings() {
  const breadcrumbs = useBreadcrumb();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { permissions, modules } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [menu, setMenu] = useState<string | null>("");
  const [module, setModule] = useState<string | null>("");

  const { data: getData, isLoading } = useSWR(params.id && params.id !== "new" ? `/usr/role?${params.id}` : null, async () => {
    if (params.id && params.id !== "new") {
      try {
        let res = await RoleApi.get(`${params.id}`);
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
        return err;
      }
    }
  });

  const form = useForm<any>({
    initialValues: {
      regUserId: null,
      partnerId: null,
      name: null,
      type: null,
      description: null,
      roleStatus: null,
      regUser: {
        avatar: null,
        lastName: null,
        firstName: null,
      },
      modules: [
        {
          code: null,
          name: null,
          hasModulePermissions: false,
          menus: [
            {
              code: null,
              name: null,
              hasMenuPermissions: false,
              permissions: [
                {
                  code: null,
                  name: null,
                  module: null,
                  menu: null,
                  portal: null,
                  isView: null,
                  isCreate: null,
                  isEdit: null,
                  isReview: null,
                  isDelete: null,
                  hasAccess: false,
                },
              ],
            },
          ],
        },
      ],
    },
    validate: yupResolver(schema),
  });

  useEffect(() => {
    setLoading(true);
    if (getData) form.setValues(getData);
    setLoading(false);
  }, [getData, setLoading, form]);

  function ModuleIcons(index: number, disabled: boolean) {
    if (index === 0)
      return (
        <ActionIcon color="blue" variant="light" size={"md"} disabled={disabled}>
          <IconMoneybag />
        </ActionIcon>
      );
    if (index === 1)
      return (
        <ActionIcon color="inventory" variant="light" size={"md"} disabled={disabled}>
          <IconInbox />
        </ActionIcon>
      );
    if (index === 2)
      return (
        <ActionIcon color="orange" variant="light" size={"md"} disabled={disabled}>
          <IconFileInvoice />
        </ActionIcon>
      );
    if (index === 3)
      return (
        <ActionIcon color="network" variant="light" size={"md"} disabled={disabled}>
          <IconNetwork />
        </ActionIcon>
      );
    if (index === 4)
      return (
        <ActionIcon color="cyan" variant="light" size={"md"} disabled={disabled}>
          <IconLayoutList />
        </ActionIcon>
      );
    if (index === 5)
      return (
        <ActionIcon color="blue" variant="light" size={"md"} disabled={disabled}>
          <IconWallet />
        </ActionIcon>
      );
    if (index === 6)
      return (
        <ActionIcon color="teal" variant="light" size={"md"} disabled={disabled}>
          <IconBuildingBank />
        </ActionIcon>
      );
    if (index === 7)
      return (
        <ActionIcon color="indigo" variant="light" size={"md"} disabled={disabled}>
          <IconUsers />
        </ActionIcon>
      );
  }

  function UserModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="indigo" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconUser />}
        {index === 1 && <IconUserCheck />}
        {index === 2 && <IconMoneybag />}
      </ActionIcon>
    );
  }

  function PartnerModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="teal" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconId />}
        {index === 1 && <IconTruck />}
        {index === 2 && <IconCashBanknote />}
        {index === 3 && <IconHome2 />}
        {index === 4 && <IconBuildingWarehouse />}
      </ActionIcon>
    );
  }

  function PaymentModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="blue" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconChartPie />}
        {index === 1 && <IconBuildingBank />}
        {index === 2 && <IconReceiptRefund />}
        {index === 3 && <IconReceipt />}
      </ActionIcon>
    );
  }

  function OrderModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="cyan" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconChartPie />}
        {index === 1 && <IconListDetails />}
        {index === 2 && <IconBox />}
        {index === 3 && <IconBox />}
        {index === 4 && <IconTruckDelivery />}
        {index === 5 && <IconBox />}
        {index === 6 && <IconTruckDelivery />}
        {index === 7 && <IconUserSearch />}
        {index === 8 && <IconChartPie />}
      </ActionIcon>
    );
  }

  function NetworkdModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="network" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconChartPie />}
        {index === 1 && <IconMail />}
        {index === 2 && <IconMailForward />}
        {index === 3 && <IconUserSearch />}
        {index === 4 && <IconDiscount2 />}
        {index === 5 && <IconWallet />}
        {index === 6 && <IconUsers />}
        {index === 7 && <IconChartGridDots />}
        {index === 8 && <IconMapSearch />}
      </ActionIcon>
    );
  }

  function InvoiceModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="orange" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconChartPie />}
        {index === 1 && <IconReceipt />}
        {index === 2 && <IconReceiptOff />}
        {index === 3 && <IconUserDollar />}
        {index === 4 && <IconCoins />}
      </ActionIcon>
    );
  }

  function InventoryModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="inventory" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconChartPie />}
        {index === 1 && <IconPackage />}
        {index === 2 && <IconReceiptRefund />}
        {index === 3 && <IconReceiptRefund />}
        {index === 4 && <IconRepeat />}
        {index === 5 && <IconReceiptTax />}
        {index === 6 && <IconUser />}
        {index === 7 && <IconReceipt />}
        {index === 8 && <IconTruck />}
        {index === 8 && <IconReport />}
      </ActionIcon>
    );
  }

  function FinanceModuleIcons(index: number, active: boolean) {
    return (
      <ActionIcon color="blue" variant={!active ? "light" : "filled"} size={"md"}>
        {index === 0 && <IconSettings />}
      </ActionIcon>
    );
  }

  async function onSubmit(values: {
    name: string;
    description: string;
    permissions: {
      code: string;
      isView: null | boolean;
      isCreate: null | boolean;
      isEdit: null | boolean;
      isReview: null | boolean;
      isDelete: null | boolean;
    }[];
  }) {
    if (values.permissions.length > 0) {
      setLoading(true);
      try {
        if (params.id === "new") {
          await RoleApi.create(values);
          message.success(`Роль амжилттай үүсгэж хадгаллаа.`);
        } else {
          await RoleApi.update(`${params.id}`, values);
          message.success(`Роль амжилттай тохируулж хадгаллаа.`);
        }
        navigate("/roles");
      } catch (error: any) {
        message.error(error.message);
        setLoading(false);
      }
      setLoading(false);
    } else message.error("Та эхлээд роль тохируулна уу!");
  }

  if (isLoading) return <LoadingOverlay visible />;
  else
    return (
      <div>
        <form
          onSubmit={form.onSubmit(
            async (values) =>
              await onSubmit({
                name: values.name,
                description: values.description,
                permissions: [].concat(
                  ...values.modules.map((item: any) =>
                    item.permissions
                      .filter((item3: any) => item3.hasUpdate === true)
                      .map((item2: any) => {
                        return {
                          code: item2.code,
                          isView: item2.isView,
                          isCreate: item2.isCreate,
                          isEdit: item2.isEdit,
                          isReview: item2.isReview,
                          isDelete: item2.isDelete,
                        };
                      }),
                  ),
                ),
              }),
          )}>
          <PageLayout
            title={params.id === "new" ? "Шинээр роль үүсгэх" : params.type === "details" ? "Хэрэглэгчийн роль дэлгэрэнгүй" : "Хэрэглэгчийн роль засварлах"}
            subTitle={
              params.id === "new"
                ? "Хэрэглэгчийн роль шинээр үүсгэх"
                : params.type === "details"
                ? "Хэрэглэгчийн роль дэлгэрэнгүй"
                : "Хэрэглэгчийн роль засварлах"
            }
            breadcrumb={breadcrumbs?.map((item: any, index: number) => {
              if (breadcrumbs?.length - 1 === index) return { ...item, label: "Дэлгэрэнгүй" };
              else return item;
            })}
            extra={[
              getData?.type !== "STANDARD"
                ? params?.type !== "details" && (
                    <Button disabled loading={loading} key={1} type="submit">
                      Ролийг хадгалах
                    </Button>
                  )
                : "",
            ]}>
            <LoadingOverlay visible={loading} />
            <Divider mb="lg" />
            <Paper>
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Ролийн нэр" required>
                    <Text fz={"sm"} fw={600} c="blue.9">
                      {form.values.name}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Роль бүртгэсэн" required>
                    <Text fz={"sm"} fw={600} c="blue.9">
                      {form.values?.regUser?.lastName || user?.lastName || "-"} {form.values?.regUser?.firstName || user?.firstName || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Бүртгэсэн огноо, цаг" required>
                    <Text fz={"sm"} fw={600} c="blue.9">
                      {dateTimeFormat(form.values.createdAt)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
              <Space h="lg" />
            </Paper>
            <Paper>
              <Flex gap={"sm"} direction={"column"}>
                {form.values?.modules?.map((item: any, index: number) => {
                  return (
                    <Flex direction={"column"} key={`${item.code}_${index}`}>
                      <UnstyledButton
                        disabled={!item.hasModulePermissions}
                        onClick={() => {
                          if (item.code === module) setModule(null);
                          else setModule(item.code);
                          setMenu(null);
                        }}>
                        <Paper p={"xs"} withBorder bg={item.code === module ? "blue.0" : ""}>
                          <Flex gap={"sm"} align={"center"} justify={"space-between"}>
                            <Group>
                              {ModuleIcons(index, !item.hasModulePermissions)}
                              <Text>{item.name}</Text>
                            </Group>
                            <Box hidden={!item.hasModulePermissions}>
                              {item.code === module ? <IconChevronDown size={"1.2rem"} color="gray" /> : <IconChevronRight size={"1.2rem"} color="gray" />}
                            </Box>
                          </Flex>
                        </Paper>
                      </UnstyledButton>
                      <Collapse in={item.code === module}>
                        <Paper mt={"xs"}>
                          <Flex direction={"column"} gap={"xs"}>
                            {form.values?.modules
                              ?.find((f: any) => f.code === module)
                              ?.menus.map((item2: any, index2: number) => {
                                return (
                                  <div key={index2}>
                                    <UnstyledButton
                                      onClick={() => {
                                        if (item2.code === menu) setMenu(null);
                                        else setMenu(item2.code);
                                      }}
                                      w={"100%"}>
                                      <Paper p={"xs"} withBorder bg={item2.code === module ? "blue.0" : ""}>
                                        <Flex gap={"sm"} align={"center"} justify={"space-between"}>
                                          <Group>
                                            <ActionIcon color="indigo" variant="light" size={"md"}>
                                              {index === 0 && FinanceModuleIcons(index2, item2.code === menu)}
                                              {index === 1 && InventoryModuleIcons(index2, item2.code === menu)}
                                              {index === 2 && InvoiceModuleIcons(index2, item2.code === menu)}
                                              {index === 3 && NetworkdModuleIcons(index2, item2.code === menu)}
                                              {index === 4 && OrderModuleIcons(index2, item2.code === menu)}
                                              {index === 5 && PaymentModuleIcons(index2, item2.code === menu)}
                                              {index === 6 && PartnerModuleIcons(index2, item2.code === menu)}
                                              {index === 7 && UserModuleIcons(index2, item2.code === menu)}
                                            </ActionIcon>
                                            <Text>{item2.name}</Text>
                                            {params.type === "TEST" && (
                                              <Text fw={500} c="red">
                                                {item2.code}
                                              </Text>
                                            )}
                                          </Group>
                                          <Box>
                                            {item2.code === menu ? (
                                              <IconChevronDown size={"1.2rem"} color="gray" />
                                            ) : (
                                              <IconChevronRight size={"1.2rem"} color="gray" />
                                            )}
                                          </Box>
                                        </Flex>
                                      </Paper>
                                    </UnstyledButton>
                                    <Collapse in={menu === item2.code}>
                                      <Paper mih={"100px"} withBorder mt={"xs"} style={{ overflow: "auto", width: "100%" }} pb={"sm"}>
                                        {menu === item2.code &&
                                          form.values?.modules
                                            ?.filter((f: any) => f.code === module)
                                            ?.map((item: any, index2: number) => {
                                              return (
                                                <Paper key={`${item.code}_${index2}`} miw={"max-content"}>
                                                  <div>
                                                    <>
                                                      {item.menus
                                                        ?.filter((f: any) => f.code === menu)
                                                        ?.map((item2: any, index2: number) => {
                                                          return (
                                                            <Paper key={`${item2.code}_${index2}`}>
                                                              <div>
                                                                <Table>
                                                                  <Table.Thead>
                                                                    <Table.Tr>
                                                                      <Table.Th style={{ width: "300px" }}>Үйлдэл</Table.Th>
                                                                      <Table.Th style={{ width: "200px" }}>Portal</Table.Th>
                                                                      <Table.Th style={{ width: "130px" }}>Has access</Table.Th>
                                                                      <Table.Th style={{ width: "130px" }}>View</Table.Th>
                                                                      <Table.Th style={{ width: "130px" }}>Create</Table.Th>
                                                                      <Table.Th style={{ width: "130px" }}>Edit</Table.Th>
                                                                      <Table.Th style={{ width: "130px" }}>Review</Table.Th>
                                                                      <Table.Th style={{ width: "130px" }}>Delete</Table.Th>
                                                                    </Table.Tr>
                                                                  </Table.Thead>
                                                                  <Table.Tbody>
                                                                    {item2.permissions.map((item3: any, index3: number) => {
                                                                      return (
                                                                        <Table.Tr key={`${item3.code}_${index3}`}>
                                                                          <Table.Td>
                                                                            <Text w={"600px"}>{item3.name} </Text>
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            <Group>
                                                                              <Badge
                                                                                color={
                                                                                  item3.portal === "BUYER"
                                                                                    ? item3.portal === "BOTH"
                                                                                      ? "grape"
                                                                                      : "pink"
                                                                                    : item3.portal === "BOTH"
                                                                                    ? "grape"
                                                                                    : "indigo"
                                                                                }
                                                                                variant="filled">
                                                                                {item3.portal}
                                                                              </Badge>
                                                                              {params.type === "TEST" && (
                                                                                <Text fz={"sm"} fw={600} color="indigo">
                                                                                  {item3.code}
                                                                                </Text>
                                                                              )}
                                                                            </Group>
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            {item3.hasAccess === null ? (
                                                                              <Text c="dimmed">-</Text>
                                                                            ) : (
                                                                              <Switch
                                                                                thumbIcon={
                                                                                  item3?.hasAccess ? (
                                                                                    <IconCheck size={13} stroke={4} color="blue" />
                                                                                  ) : (
                                                                                    <IconX size={13} stroke={4} color="gray" />
                                                                                  )
                                                                                }
                                                                                onChange={() => {}}
                                                                                readOnly
                                                                                checked={item3?.hasAccess || false}
                                                                              />
                                                                            )}
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            {item3.isView === null ? (
                                                                              <Text c="dimmed">-</Text>
                                                                            ) : (
                                                                              <Switch
                                                                                thumbIcon={
                                                                                  item3?.isView ? (
                                                                                    <IconCheck size={13} stroke={4} color="blue" />
                                                                                  ) : (
                                                                                    <IconX size={13} stroke={4} color="gray" />
                                                                                  )
                                                                                }
                                                                                onChange={() => {}}
                                                                                readOnly
                                                                                checked={item3?.isView || false}
                                                                              />
                                                                            )}
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            {item3.isCreate === null ? (
                                                                              <Text c="dimmed">-</Text>
                                                                            ) : (
                                                                              <Switch
                                                                                thumbIcon={
                                                                                  item3?.isCreate ? (
                                                                                    <IconCheck size={13} stroke={4} color="blue" />
                                                                                  ) : (
                                                                                    <IconX size={13} stroke={4} color="gray" />
                                                                                  )
                                                                                }
                                                                                onChange={() => {}}
                                                                                readOnly
                                                                                checked={item3?.isCreate || false}
                                                                              />
                                                                            )}
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            {item3.isEdit === null ? (
                                                                              <Text c="dimmed">-</Text>
                                                                            ) : (
                                                                              <Switch
                                                                                thumbIcon={
                                                                                  item3?.isEdit ? (
                                                                                    <IconCheck size={13} stroke={4} color="blue" />
                                                                                  ) : (
                                                                                    <IconX size={13} stroke={4} color="gray" />
                                                                                  )
                                                                                }
                                                                                onChange={() => {}}
                                                                                readOnly
                                                                                checked={item3?.isEdit || false}
                                                                              />
                                                                            )}
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            {item3.isReview === null ? (
                                                                              <Text c="dimmed">-</Text>
                                                                            ) : (
                                                                              <Switch
                                                                                thumbIcon={
                                                                                  item3?.isReview ? (
                                                                                    <IconCheck size={13} stroke={4} color="blue" />
                                                                                  ) : (
                                                                                    <IconX size={13} stroke={4} color="gray" />
                                                                                  )
                                                                                }
                                                                                onChange={() => {}}
                                                                                readOnly
                                                                                checked={item3?.isReview || false}
                                                                              />
                                                                            )}
                                                                          </Table.Td>
                                                                          <Table.Td>
                                                                            {item3.isDelete === null ? (
                                                                              <Text c="dimmed">-</Text>
                                                                            ) : (
                                                                              <Switch
                                                                                thumbIcon={
                                                                                  item3?.isDelete ? (
                                                                                    <IconCheck size={13} stroke={4} color="blue" />
                                                                                  ) : (
                                                                                    <IconX size={13} stroke={4} color="gray" />
                                                                                  )
                                                                                }
                                                                                onChange={() => {}}
                                                                                readOnly
                                                                                checked={item3?.isDelete || false}
                                                                              />
                                                                            )}
                                                                          </Table.Td>
                                                                        </Table.Tr>
                                                                      );
                                                                    })}
                                                                  </Table.Tbody>
                                                                </Table>
                                                              </div>
                                                            </Paper>
                                                          );
                                                        })}
                                                    </>
                                                  </div>
                                                </Paper>
                                              );
                                            })}
                                      </Paper>
                                    </Collapse>
                                  </div>
                                );
                              })}
                          </Flex>
                        </Paper>
                      </Collapse>
                    </Flex>
                  );
                })}
              </Flex>
              <Space h={"lg"} />
            </Paper>
          </PageLayout>
        </form>
      </div>
    );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Эрхийн тохиргоо",
    to: "/roles",
  },
  {
    label: "Шинэ роль",
  },
];
