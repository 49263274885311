import { ActionIcon, Badge, Flex, Group, MultiSelect, Paper, Select, Text, Tooltip } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { IconDeviceFloppy, IconEdit, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { UserApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";

const schema = yup.object().shape({
  roles: yup
    .array()
    .of(
      yup.object().shape({
        businessId: yup.string().required("Заавал бөглөнө!").nullable(),
        accessLevel: yup.string().required("Заавал бөглөнө!").nullable(),
        roleIds: yup.array().of(yup.string().required("Заавал бөглөнө!")).min(0, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
      }),
    )
    .required("Заавал бөглөнө!"),
});

export function RoleSettingsTab({ data, reload }: { data: any; reload: () => void }) {
  const { accessLevels, businesses, userRoleTypes, roles } = useSelector((state: { general: IGeneral }) => state.general);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [action, setAction] = useState<any[]>([]);

  function removeDuplicateObjects(objects: any[], prop: keyof any): any[] {
    const uniqueObjectsMap = new Map();
    objects.forEach((obj) => {
      uniqueObjectsMap.set(obj[prop], obj);
    });
    return Array.from(uniqueObjectsMap.values());
  }

  const form = useForm<any>({
    initialValues: {
      userId: data.id,
      roles: removeDuplicateObjects(
        [...businesses, ...data.userRoles].map((item: any) => {
          return {
            businessId: item.id,
            businesses: {
              id: item.id,
              refCode: item.refCode,
              profileName: item.profileName,
              type: item.type,
            },
            roleIds:
              item?.roles?.map((item2: any) => {
                return `${item2.id}`;
              }) || [],
            roles:
              item?.roles?.map((item2: any) => {
                return {
                  id: item2.id,
                  name: item2.name,
                };
              }) || [],
            accessLevel: item.accessLevel,
          };
        }),
        "businessId",
      ),
    },
    validate: yupResolver(schema),
  });

  const updated = () => {
    form.setValues((currentValues) => {
      const newValues = {
        userId: data.id,
        roles: removeDuplicateObjects(
          [...businesses, ...data?.userRoles].map((item: any) => ({
            businessId: item.id,
            businesses: {
              id: item.id,
              refCode: item.refCode,
              profileName: item.profileName,
              type: item.type,
            },
            roleIds: item?.roles?.map((item2: any) => `${item2.id}`) || [],
            roles:
              item?.roles?.map((item2: any) => ({
                id: item2.id,
                name: item2.name,
              })) || [],
            accessLevel: item.accessLevel,
          })),
          "businessId",
        ),
      };

      if (JSON.stringify(currentValues) !== JSON.stringify(newValues)) {
        return newValues;
      }

      return currentValues;
    });
  };

  const columns = useHeader({
    accessLevels,
    userRoleTypes,
    action,
    setAction,
    roles,
    onClick: (key, item) => {
      switch (key) {
        case "role_edit": {
          setAction(["role_edit", item]);
          break;
        }
      }
    },
    form,
  });

  useEffect(() => {
    if (data) {
      updated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, businesses]);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      setAction([]);
      let res = await UserApi.roleAdd({
        userId: values.userId,
        roles: values.roles.map((item: any) => {
          return {
            businessId: item.businessId,
            roleIds: item.roleIds,
            accessLevel: item.accessLevel,
          };
        }),
      });
      if (res) {
        message.success("Үйлдэл амжилттай.");
        reload();
      }
    } catch (error: any) {
      error?.message && message.error(error?.message);
    }
    setIsLoading(false);
  };

  return (
    <Paper>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Group justify="space-between">
          <Text fz={"lg"} fw={500} my={"sm"}>
            Бизнесүүдэд олгох эрх
          </Text>
          <div>
            {action[0] === "edit" ? (
              <Group>
                <Tooltip label="Хадгалах">
                  <ActionIcon loading={isLoading} type="submit" color="" variant="light">
                    <IconDeviceFloppy size={"1.4rem"} />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Болих">
                  <ActionIcon
                    onClick={() => {
                      form.reset();
                      setAction([]);
                    }}
                    color="red"
                    variant="light">
                    <IconX size={"1.4rem"} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ) : (
              <Tooltip label="Засах">
                <ActionIcon onClick={() => setAction(["edit"])} variant="light" color="">
                  <IconEdit size={"1.4rem"} />
                </ActionIcon>
              </Tooltip>
            )}
          </div>
        </Group>
        <Table name={`user.management.roleList`} columns={columns} dataSource={form.values?.roles || []} />
      </form>
    </Paper>
  );
}

type HeaderProps = {
  onClick: (key: string, record: any) => void;
  accessLevels: IReference[];
  userRoleTypes: IReference[];
  action: any[];
  roles: any[];
  form: any;
  setAction: any;
};

const useHeader = ({ onClick, accessLevels, userRoleTypes, action, roles, form, setAction }: HeaderProps): ColumnType<any>[] => [
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <div>
          <Text size="sm" w="max-content" c="indigo">
            #{record?.businesses.refCode}
          </Text>
          <Text size="sm" w="max-content">
            {record?.businesses.profileName}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Portal",
    sorter: true,
    dataIndex: "type",
    render: (record) => (
      <div>
        {record?.businesses?.type === "SUPPLIER" && (
          <Badge color="violet" variant="outline">
            Нийлүүлэгч
          </Badge>
        )}
        {record?.businesses?.type === "BUYER" && (
          <Badge color="orange" variant="outline">
            Худалдан авагч
          </Badge>
        )}
      </div>
    ),
  },
  {
    title: "Хэрэглэгчийн роль",
    sorter: true,
    width: "400px",
    render: (record, index) => {
      return action[0] === "edit" ? (
        <MultiSelect
          defaultValue={record?.roles.map((item: any) => {
            return `${item.id}`;
          })}
          multiple
          miw={"370px"}
          data={roles
            .filter((f: any) => f.name !== "Admin")
            .map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
          placeholder="Хэрэглэгчийн роль сонгох"
          {...form.getInputProps(`roles.${index}.roleIds`)}
        />
      ) : (
        <Flex gap="sm">
          {record?.roles.map((item: any) => (
            <Badge key={item.id}>{item.name ?? "-"}</Badge>
          ))}
        </Flex>
      );
    },
  },
  {
    title: "Order түвшин",
    sorter: true,
    dataIndex: "accessLevel",
    render: (record, index) =>
      action[0] === "edit" ? (
        <Select
          data={accessLevels.map((item: any) => {
            return {
              label: item.name,
              value: item.code,
            };
          })}
          placeholder="Order түвшин сонгох"
          {...form.getInputProps(`roles.${index}.accessLevel`)}
        />
      ) : (
        <Text size="sm" w="max-content">
          {accessLevels?.find((item: IReference) => item.code === record?.accessLevel)?.name || "-"}
        </Text>
      ),
  },
];
