import { Alert, Box, Button, Divider, Group, Select, Text } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi, FinanceSettingsApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";

const schema = yup.object({
  pin: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .typeError("Зөвхөн тоон утга оруулна")
    .max(6, "Хэт урт sбайна!")
    .min(6, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
  bank: yup.string().required("Заавал бөглөнө!").nullable(),
  userId: yup.string().required("Заавал бөглөнө!").nullable(),
});

export function ScfDcfForm({ action, setAction, reload }: { action: any[]; setAction: React.Dispatch<React.SetStateAction<any[]>>; reload: () => void }) {
  const [loading, setLoadind] = useState<boolean>(false);
  const { bankNames } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = useState<any>({
    pin: "",
    bank: action[1] && action[1].code,
  });

  const onSubmit = async (values: any) => {
    setLoadind(true);
    try {
      let res = await AuthApi.checkPin({ pin: `${values.pin}` });
      if (res?.success) {
        await FinanceSettingsApi.create({ bank: values.bank });
        const now = new Date();

        message.success(`Банк нээлттэй тохиргоо хийлээ. Та банктай харилцан ажиллах ажилтны эрхийг тохируулна уу ${dateTimeFormat(now)}`);
        reload();

        setAction([]);
      }
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй");
    }
    setLoadind(false);
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values }) => {
        return (
          <Box style={{ display: "flex", gap: 20, flexDirection: "column" }}>
            <Alert>
              <Text component="span">
                Та санхүүжилтийн үйлчилгээ авах Finance системд хандаж ажиллах эрх хүсэх гэж байна. Та итгэлтэй байвал ПИН кодоо оруулж баталгаажуулна уу.
              </Text>
            </Alert>
            <Select
              value={values.bank}
              label="Харилцахыг хүсэж буй банкны нэр"
              data={bankNames.map((item: any) => {
                return {
                  value: item.code,
                  label: item.name,
                };
              })}
              readOnly
            />
            <OtpField name="pin" label="Итгэлтэй бол ПИН кодоо оруулж баталгаажуулна уу" size="md" />
            <Divider color="gray.5" />
            <Group justify="space-between">
              <Button onClick={() => setAction([])} variant="default">
                Буцах
              </Button>
              <Button loading={loading} type="submit">
                Зөвшөөрөх
              </Button>
            </Group>
          </Box>
        );
      }}
    </Form>
  );
}
