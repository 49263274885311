import { Popover, Text } from "@mantine/core";

export function PopoverText({
  children,
  withArrow = true,
  position = "top",
}: {
  children: string;
  withArrow?: boolean;
  position?: "top" | "left" | "bottom" | "right";
}) {
  if (children && children.length > 18)
    return (
      <Popover width={"max-content"} position={position} withArrow={withArrow} shadow="sm">
        <Popover.Target>
          <Text component="a" w="max-conent" size="sm">
            {children?.substring(0, 25) ?? ""}...
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">{children}</Text>
        </Popover.Dropdown>
      </Popover>
    );
  else return <Text>{children}</Text>;
}
