import { ActionIcon, Avatar, Badge, Flex, Paper, Text, Tooltip } from "@mantine/core";
import { IconCloudLock, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { FinanceSettingsApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IApprovelRuleLevel } from "../../interfaces/IApprovelRuleLevel";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { Tag } from "../tags";

export function ScfDcfList({ action, setAction, tableRef }: { action: any; setAction: any; tableRef: any }) {
  const [filters] = React.useState({ query: "" });
  const { financeRoleBankStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    financeRoleBankStatus,
    onClick: (key, item) => {
      switch (key) {
        case "request": {
          setAction(["request", item as any]);
          break;
        }
      }
    },
  });

  return (
    <Table
      pagination={false}
      ref={tableRef}
      name="usr/finance_settings/business"
      columns={columns}
      filters={filters}
      loadData={(data) => FinanceSettingsApi.bank_list(data!)}
    />
  );
}

type HeaderProps = {
  onClick: (key: string, record: IApprovelRuleLevel) => void;
  financeRoleBankStatus: IReference[];
};

const useHeader = ({ onClick, financeRoleBankStatus }: HeaderProps): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Хүсэлт">
          <ActionIcon variant="light" onClick={() => onClick("request", record)} color="" disabled={!!record?.financeRole}>
            <IconCloudLock />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "Лого",
    render: (record) => {
      return (
        <Paper withBorder radius="xl">
          <Avatar src={record?.icon}>
            <IconPhoto />
          </Avatar>
        </Paper>
      );
    },
  },
  {
    title: "Банкны нэр",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.name || "-"}
      </Text>
    ),
  },
  {
    title: "ТТД",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.regNumber || "-"}
      </Text>
    ),
  },

  {
    title: "Партнер нэр",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.businessName || "-"}
      </Text>
    ),
  },
  {
    title: "Банканд нээлттэй",
    render: (record) => <Badge color={!!record?.financeRole ? "green.5" : "red.5"}>{!!record?.financeRole ? "Тийм" : "Үгүй"}</Badge>,
  },

  {
    title: "Банк статус",
    render: (record) => {
      return (
        <Tag
          color={financeRoleBankStatus.find((item: IReference) => item.code === record.financeRole?.bankStatus)?.color ?? "gray.5"}
          name={financeRoleBankStatus.find((item: IReference) => item.code === record.financeRole?.bankStatus)?.name ?? "-"}
        />
      );
    },
  },

  {
    title: "SCF/DCF санхүү",
    render: (record) => {
      if (record?.financeRole?.user)
        return (
          <Flex align="center" gap={10}>
            <Avatar src={record?.financeRole?.user?.avatar} />
            <Text tt={"capitalize"} size="sm">
              {record?.financeRole?.user?.lastName ? record?.financeRole?.user?.lastName[0] + "." : ""}
              {record?.financeRole?.user?.firstName ?? "-"}
            </Text>
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Регистр №",
    render: (record) => {
      return (
        <Text size="sm" tt="uppercase" w="max-content">
          {record?.financeRole?.user?.registerNo ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "SCF/DCF Username",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.financeRole?.username ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Гар утас",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.financeRole?.user?.phone ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "И-мэйл",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.financeRole?.user?.email ?? "-"}
        </Text>
      );
    },
  },
];
